
export enum Currency {
    ADP = 'ADP',
    AED = 'AED',
    AFA = 'AFA',
    ALL = 'ALL',
    AMD = 'AMD',
    ANG = 'ANG',
    AOK = 'AOK',
    AON = 'AON',
    ARA = 'ARA',
    ARS = 'ARS',
    ATS = 'ATS',
    AUD = 'AUD',
    AWG = 'AWG',
    AZM = 'AZM',
    AZN = 'AZN',
    BBD = 'BBD',
    BDT = 'BDT',
    BEF = 'BEF',
    BGN = 'BGN',
    BHD = 'BHD',
    BIF = 'BIF',
    BMD = 'BMD',
    BND = 'BND',
    BOB = 'BOB',
    BOP = 'BOP',
    BOV = 'BOV',
    BRC = 'BRC',
    BRL = 'BRL',
    BSD = 'BSD',
    BTN = 'BTN',
    BUK = 'BUK',
    BWP = 'BWP',
    BYB = 'BYB',
    BZD = 'BZD',
    CAD = 'CAD',
    CHF = 'CHF',
    CLP = 'CLP',
    CNY = 'CNY',
    COP = 'COP',
    CRC = 'CRC',
    CSD = 'CSD',
    CUP = 'CUP',
    CVE = 'CVE',
    CYP = 'CYP',
    CZK = 'CZK',
    DEM = 'DEM',
    DJF = 'DJF',
    DKK = 'DKK',
    DOP = 'DOP',
    DZD = 'DZD',
    ECS = 'ECS',
    EEK = 'EEK',
    EGP = 'EGP',
    ESP = 'ESP',
    ETB = 'ETB',
    EUR = 'EUR',
    FIM = 'FIM',
    FJD = 'FJD',
    FKP = 'FKP',
    FRF = 'FRF',
    GBP = 'GBP',
    GHC = 'GHC',
    GHS = 'GHS',
    GIP = 'GIP',
    GMD = 'GMD',
    GNF = 'GNF',
    GNS = 'GNS',
    GQE = 'GQE',
    GRD = 'GRD',
    GTQ = 'GTQ',
    GWP = 'GWP',
    GYD = 'GYD',
    HKD = 'HKD',
    HNL = 'HNL',
    HRK = 'HRK',
    HTG = 'HTG',
    HUF = 'HUF',
    IDR = 'IDR',
    IEP = 'IEP',
    ILS = 'ILS',
    INR = 'INR',
    IQD = 'IQD',
    IRR = 'IRR',
    ISK = 'ISK',
    ITL = 'ITL',
    JMD = 'JMD',
    JOD = 'JOD',
    JPY = 'JPY',
    KES = 'KES',
    KGS = 'KGS',
    KHR = 'KHR',
    KMF = 'KMF',
    KPW = 'KPW',
    KRW = 'KRW',
    KWD = 'KWD',
    KYD = 'KYD',
    KZT = 'KZT',
    LAK = 'LAK',
    LBP = 'LBP',
    LKR = 'LKR',
    LRD = 'LRD',
    LSM = 'LSM',
    LTL = 'LTL',
    LUF = 'LUF',
    LVL = 'LVL',
    LYD = 'LYD',
    MAD = 'MAD',
    MDL = 'MDL',
    MGA = 'MGA',
    MGF = 'MGF',
    MKD = 'MKD',
    MLF = 'MLF',
    MMK = 'MMK',
    MNT = 'MNT',
    MOP = 'MOP',
    MRO = 'MRO',
    MTL = 'MTL',
    MUR = 'MUR',
    MVR = 'MVR',
    MWK = 'MWK',
    MXN = 'MXN',
    MXP = 'MXP',
    MXV = 'MXV',
    MYR = 'MYR',
    MZM = 'MZM',
    NAD = 'NAD',
    NGN = 'NGN',
    NIC = 'NIC',
    NIO = 'NIO',
    NLG = 'NLG',
    NOK = 'NOK',
    NPR = 'NPR',
    NZD = 'NZD',
    OMR = 'OMR',
    PAB = 'PAB',
    PCT = 'PCT',
    PEI = 'PEI',
    PEN = 'PEN',
    PES = 'PES',
    PGK = 'PGK',
    PHP = 'PHP',
    PKR = 'PKR',
    PLN = 'PLN',
    PLZ = 'PLZ',
    PSP = 'PSP',
    PTE = 'PTE',
    PYG = 'PYG',
    QAR = 'QAR',
    ROL = 'ROL',
    RON = 'RON',
    RSD = 'RSD',
    RUB = 'RUB',
    RUR = 'RUR',
    RWF = 'RWF',
    SAR = 'SAR',
    SBD = 'SBD',
    SCR = 'SCR',
    SDD = 'SDD',
    SDP = 'SDP',
    SEK = 'SEK',
    SGD = 'SGD',
    SHP = 'SHP',
    SIT = 'SIT',
    SKK = 'SKK',
    SLL = 'SLL',
    SOS = 'SOS',
    SRG = 'SRG',
    STD = 'STD',
    SUR = 'SUR',
    SVC = 'SVC',
    SYP = 'SYP',
    SZL = 'SZL',
    THB = 'THB',
    TJR = 'TJR',
    TMM = 'TMM',
    TND = 'TND',
    TOP = 'TOP',
    TPE = 'TPE',
    TRL = 'TRL',
    TRY = 'TRY',
    TTD = 'TTD',
    TWD = 'TWD',
    TZS = 'TZS',
    UAH = 'UAH',
    UAK = 'UAK',
    UGX = 'UGX',
    USD = 'USD',
    UYP = 'UYP',
    UYU = 'UYU',
    UZS = 'UZS',
    VEB = 'VEB',
    VND = 'VND',
    VUV = 'VUV',
    WST = 'WST',
    XAF = 'XAF',
    XCD = 'XCD',
    XEU = 'XEU',
    XOF = 'XOF',
    XPF = 'XPF',
    YDD = 'YDD',
    YER = 'YER',
    YUD = 'YUD',
    ZAL = 'ZAL',
    ZAR = 'ZAR',
    ZMK = 'ZMK',
    ZMW = 'ZMW',
    ZRZ = 'ZRZ',
    ZWD = 'ZWD',
    PERCENT = '%'
}

export function currencyLabel(value: string): string {
	if( value == null || value === "" || ! value.length )
        return "";
	try {
		let index = Object.keys(Currency).indexOf( value.toString().toUpperCase() );
  		if( index >= 0) 
			return Object.values(Currency)[index];
	}catch (e){
		console.error("Failed to get Currency label for '" + value + "'", e);
	}
	console.error("Currency label for '" + value + "' doest exist");
		
	return value;
}