import * as React from "react";

import { Td, Tr } from "@bdl-cmn-shared-packages-npm/design-system";
import {css, jsx} from "@emotion/react";

import {formatDate} from "../../../../../../utils/date";
import {sharedStyle} from "../../../../../../styles/shared";
import {tradingStyle} from "../../trading-data.style";
import { goToSecurityDetailPage, isSecurityClickable } from "../../../../SecurityCard";
import { marketTransfersLabel } from "../../../../../../enums/market-transfers";

/** @jsx jsx */

export const MarketTransfersRow = (props:any) => {
    const row = props.marketTransfersRow;
    
    if(!row?.marketTransfers || !row?.marketTransfers[0])
        return <></>;

    const clickable = isSecurityClickable(row.status);

  return (
    <Tr onRowClick={() => { if(clickable) goToSecurityDetailPage(row.isin, row.id)}}
        css={[sharedStyle.tableRow, !clickable && sharedStyle.tableRowNoClickable]}>
        <Td>
            <div>
                <div css={tradingStyle.fnsTitle}>{row.instrumentName}</div>
                  <div css={tradingStyle.fnsText}>
                      {row.issuedNominalAmount ? `${row.isin} ${row.currency} ${row.issuedNominalAmount}` : `${row.isin}`}
                  </div>
                <div css={tradingStyle.fnsDate}>
                    {marketTransfersLabel(row.marketTransfers[0]?.marketBefore.trim())}
                    {` -> `}
                    {marketTransfersLabel(row.marketTransfers[0]?.marketAfter.trim())}
                    {` | `}
                    {formatDate(row.marketTransfers[0]?.changeUtcDt)}
                </div>
            </div>
        </Td>
    </Tr>
    );
}
export default MarketTransfersRow;