
export enum CurrencyLong {
    ADP = 'Andorran peseta',
    AED = 'United Arab Emirates dirham',
    AFA = 'Afghani',
    ALL = 'Lek',
    AMD = 'Armenian dram',
    ANG = 'Netherlands Antillean guilder',
    AOK = 'Kwanza',
    AON = 'New Kwanza',
    ARA = 'Austral',
    ARS = 'Argentine peso',
    ATS = 'Schilling',
    AUD = 'Australian dollar',
    AWG = 'Aruban guilder',
    AZM = 'Former Azerbaijanian manat',
    AZN = 'MANAT AZERBAIDJANAIS',
    BBD = 'Barbados dollar',
    BDT = 'Bangladeshi taka',
    BEF = 'Belgian franc',
    BGN = 'Lev',
    BHD = 'Bahraini dinar',
    BIF = 'Burundian franc',
    BMD = 'Bermudian dollar',
    BND = 'Brunei dollar',
    BOB = 'Boliviano',
    BOP = 'Bolivian peso',
    BOV = 'Bolivian Mvdol',
    BRC = 'Brazilian cruzeiro',
    BRL = 'Brazilian real',
    BSD = 'Bahamian dollar',
    BTN = 'Ngultrum',
    BUK = 'Kyat',
    BWP = 'Pula',
    BYB = 'Belarusian ruble',
    BZD = 'Belize dollar',
    CAD = 'Canadian dollar',
    CHF = 'Swiss franc',
    CLP = 'Chilean peso',
    CNY = 'Chinese Yuan',
    COP = 'Colombian peso',
    CRC = 'Costa Rican colon',
    CSD = 'Serbian dinar',
    CUP = 'Cuban peso',
    CVE = 'Cape Verde escudo',
    CYP = 'Cypriot pound',
    CZK = 'Czech Koruna',
    DEM = 'Deutsche Mark',
    DJF = 'Djibouti franc',
    DKK = 'Danish krone',
    DOP = 'Dominican peso',
    DZD = 'Algerian dinar',
    ECS = 'Ecuadorian sucre',
    EEK = 'Kroon',
    EGP = 'Egyptian pound',
    ESP = 'Spanish peseta',
    ETB = 'Ethiopian birr',
    EUR = 'Euro',
    FIM = 'Finnish markka',
    FJD = 'Fiji dollar',
    FKP = 'Falkland Islands pound',
    FRF = 'French franc',
    GBP = 'Pound sterling',
    GEL = 'Georgian lari',
    GHC = 'Cedi',
    GHS = 'New Cedi',
    GIP = 'Gibraltar pound',
    GMD = 'Dalasi',
    GNF = 'Guinea franc',
    GNS = 'Syli',
    GQE = 'Ekpwele',
    GRD = 'Drachma',
    GTQ = 'Quetzal',
    GWP = 'Guinea-Bissau peso',
    GYD = 'Guyana dollar',
    HKD = 'Hong Kong dollar',
    HNL = 'Lempira',
    HRK = 'Croatian kuna',
    HTG = 'Haiti gourde',
    HUF = 'Forint',
    IDR = 'Rupiah',
    IEP = 'Irish pound',
    ILS = 'Israeli new sheqel',
    INR = 'Indian rupee',
    IQD = 'Iraqi dinar',
    IRR = 'Iranian rial',
    ISK = 'Iceland krona',
    ITL = 'Italian lira',
    JMD = 'Jamaican dollar',
    JOD = 'Jordanian dinar',
    JPY = 'Japanese yen',
    KES = 'Kenyan shilling',
    KGS = 'Som',
    KHR = 'Riel',
    KMF = 'Comoro franc',
    KPW = 'North Korean won',
    KRW = 'South Korean won',
    KWD = 'Kuwaiti dinar',
    KYD = 'Cayman Islands dollar',
    KZT = 'Tenge',
    LAK = 'Kip',
    LBP = 'Lebanese pound',
    LKR = 'Sri Lanka rupee',
    LRD = 'Liberian dollar',
    LSM = 'Lesotho loti',
    LTL = 'Lithuanian litas',
    LUF = 'Luxembourgish franc',
    LVL = 'Latvian lats',
    LYD = 'Libyan dinar',
    MAD = 'Moroccan dirham',
    MDL = 'Moldovan leu',
    MGA = 'Malagasy ariary',
    MGF = 'Malagasy ariary',
    MKD = 'Denar',
    MLF = 'Malian franc',
    MMK = 'Kyat',
    MNT = 'Tugrik',
    MOP = 'Pataca',
    MRO = 'Ouguija',
    MTL = 'Maltese pound',
    MUR = 'Mauritius rupee',
    MVR = 'Rufiyaa',
    MWK = 'Kwacha',
    MXN = 'Mexican peso',
    MXP = 'Former Mexican peso',
    MXV = 'MEXICAN UNIDAD DE INVERSION (UDI)',
    MYR = 'Malaysian ringgit',
    MZM = 'Metical',
    NAD = 'Namibian dollar',
    NGN = 'Naira',
    NIC = 'Cordoba',
    NIO = 'Cordoba oro',
    NLG = 'Dutch guilder',
    NOK = 'Norwegian krone',
    NPR = 'Nepalese rupee',
    NZD = 'New Zealand dollar',
    OMR = 'Rial omani',
    PAB = 'Balboa',
    PCT = 'Czechoslovak koruna',
    PEI = 'Inti',
    PEN = 'Nuevo sol',
    PES = 'Sol',
    PGK = 'Kina',
    PHP = 'Philippine peso',
    PKR = 'Pakistan rupee',
    PLN = 'New Zloty',
    PLZ = 'Zloty',
    PSP = 'Palestine pound',
    PTE = 'Portuguese escudo',
    PYG = 'Guarani',
    QAR = 'Qatari rial',
    ROL = 'Leu',
    RON = 'New Leu',
    RSD = 'DINAR DE SERBIE',
    RUB = 'Russian ruble',
    RUR = 'Former Russian ruble',
    RWF = 'Rwanda franc',
    SAR = 'Saudi riyal',
    SBD = 'Solomon Islands dollar',
    SCR = 'Seychelles rupee',
    SDD = 'Sudanese dinar',
    SDP = 'Sudanese pound',
    SEK = 'Swedish krona',
    SGD = 'Singapore dollar',
    SHP = 'Saint Helena pound',
    SIT = 'Tolar',
    SKK = 'Slovak koruna',
    SLL = 'Leone',
    SOS = 'Somali shilling',
    SRG = 'Surinamese guilder',
    STD = 'Dobra',
    SUR = 'Ruble',
    SVC = 'Salvadoran colón',
    SYP = 'Syrian pound',
    SZL = 'Lilangeni',
    THB = 'Baht',
    TJR = 'Tajikistani ruble',
    TMM = 'Manat',
    TND = 'Tunisian dinar',
    TOP = 'Pa\'anga',
    TPE = 'Timorese escudo',
    TRL = 'New Turkish lira',
    TRY = 'Turkish lira',
    TTD = 'Trinidad and Tobago dollar',
    TWD = 'New Taiwan dollar',
    TZS = 'Tanzanian shilling',
    UAH = 'Hryvnia',
    UAK = 'Karbovanet',
    UGX = 'Uganda shilling',
    USD = 'US dollar',
    UYP = 'Peso Uruguayo',
    UYU = 'Peso Uruguayo',
    UZS = 'Uzbekistan som',
    VEB = 'Venezuelan bolívar fuerte',
    VND = 'Vietnamese dong',
    VUV = 'Vatu',
    WST = 'Samoan tala',
    XAF = 'CFA franc BEAC',
    XCD = 'East Caribbean dollar',
    XEU = 'ECU',
    XOF = 'CFA Franc BCEAO',
    XPF = 'CFP franc',
    YDD = 'South Yemeni dinar',
    YER = 'Yemeni rial',
    YUD = 'New Yugoslav dinar',
    ZAL = 'Financial Rand',
    ZAR = 'South African rand',
    ZMK = 'Kwacha',
    ZMW = 'Zambian Kwacha',
    ZRZ = 'Zairean zaire',
    ZWD = 'Zimbabwe dollar',
}

export function currencyLongLabel(value: string): string {
	if( value == null || value === "" || ! value.length )
        return "";
	try {
		let index = Object.keys(CurrencyLong).indexOf( value );
  		if( index >= 0) 
			return Object.values(CurrencyLong)[index];
	}catch (e){
		console.error("Failed to get CurrencyLong label for '" + value + "'", e);
	}
	
	console.error("CurrencyLong label for '" + value + "' - '" + value + "' doest exist");
		
	return value;
}